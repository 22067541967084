import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';

export const defaultContextValue = {
	tr: (key) => { return key; },
	getThemes: () => { return []; },
	getTheme: (key) => { return key; },
};
const themeKeys = ['themeTech', 'themeTransport', 'themeFamily', 'themeFood', 'themeSociety', 'themeAnimals'];

const GlobalContext = React.createContext(defaultContextValue);

export const GlobalContextProviderComponent = ({ children }) => {
	const { data } = useStaticQuery(graphql`
		query {
			data: allStoryblokDatasourceEntry {
				nodes {
				  name
				  value
				}
			}
		}
	`);

	const translations = data?.nodes;
	const themes = [];
	themeKeys.forEach(key => themes.push(translations.find(e => e.name === key)));

	const tr = (key) => {
		const entry = translations.find(e => e.name === key);
		return entry ? entry.value : key;
	};

	const getThemes = () => {
		return themes;
	};

	const getTheme = (key) => {
		let selectedTheme = '';
		themes.forEach(theme => {
			if(theme.name === key) {
				return selectedTheme = theme;
			}
		});
		return selectedTheme;
	};

	const contextValue = {
		...defaultContextValue,
		tr: tr,
		getThemes: getThemes,
		getTheme: getTheme,
	};
	const [ state ] = useState(contextValue); 
	return <GlobalContext.Provider value={state}>{children}</GlobalContext.Provider>;
};

export default GlobalContext;
