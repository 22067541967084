exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bonus-js": () => import("./../../../src/pages/bonus.js" /* webpackChunkName: "component---src-pages-bonus-js" */),
  "component---src-pages-elev-js": () => import("./../../../src/pages/elev.js" /* webpackChunkName: "component---src-pages-elev-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index_old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-laerer-index-js": () => import("./../../../src/pages/laerer/index.js" /* webpackChunkName: "component---src-pages-laerer-index-js" */),
  "component---src-pages-laerer-klasse-js": () => import("./../../../src/pages/laerer/klasse.js" /* webpackChunkName: "component---src-pages-laerer-klasse-js" */),
  "component---src-pages-liveshow-js": () => import("./../../../src/pages/liveshow.js" /* webpackChunkName: "component---src-pages-liveshow-js" */),
  "component---src-pages-skriv-mig-op-js": () => import("./../../../src/pages/skriv-mig-op.js" /* webpackChunkName: "component---src-pages-skriv-mig-op-js" */),
  "component---src-pages-sporgsmal-js": () => import("./../../../src/pages/sporgsmal.js" /* webpackChunkName: "component---src-pages-sporgsmal-js" */),
  "component---src-pages-udforsk-js": () => import("./../../../src/pages/udforsk.js" /* webpackChunkName: "component---src-pages-udforsk-js" */),
  "component---src-templates-answerpage-js": () => import("./../../../src/templates/answerpage.js" /* webpackChunkName: "component---src-templates-answerpage-js" */),
  "component---src-templates-previous-streams-js": () => import("./../../../src/templates/previousStreams.js" /* webpackChunkName: "component---src-templates-previous-streams-js" */),
  "component---src-templates-print-js": () => import("./../../../src/templates/print.js" /* webpackChunkName: "component---src-templates-print-js" */),
  "component---src-templates-questionpage-js": () => import("./../../../src/templates/questionpage.js" /* webpackChunkName: "component---src-templates-questionpage-js" */),
  "component---src-templates-template-js": () => import("./../../../src/templates/template.js" /* webpackChunkName: "component---src-templates-template-js" */)
}

